import { db, functions, storage } from "../firebase";
import { collection, doc, endBefore, getCountFromServer, getDoc, getDocs, limit, orderBy, query, serverTimestamp, setDoc, startAfter, updateDoc, where, or } from "firebase/firestore";
import { ref, uploadBytes, updateMetadata, getDownloadURL } from "firebase/storage";
import { httpsCallable } from "firebase/functions";

const COLLECTION_COACH_APPLICATIONS = "coachApplications";
const COLLECTION_COACHES = "coaches";
const COLLECTION_COACH_PRIVATE_DATA = "coachPrivateData";
const COLLECTION_REVIEWS = "reviews";

const STORAGE_DIR_PROFILE_PICS = "profilePics";
const STORAGE_DIR_PHOTO_IDS = "photoIds";
const STORAGE_DIR_REVIEW_VIDEOS = "reviewVideos";

export const loadCoachApplication = async (coachId) => {
  const application = await getDoc(doc(db, COLLECTION_COACH_APPLICATIONS, coachId));
  if (application.exists()) {
    return { ...application.data(), id: application.id };
  } else {
    return null;
  }
};

export const loadCoachApplications = async (params) => {
  let q = query(collection(db, COLLECTION_COACH_APPLICATIONS));

  let totalCount = 0;

  const searchParam = params?.search;
  if (searchParam) {
    q = query(q, 
      or(
        where("id", "==", searchParam),
        where("email", "==", searchParam), 
        where("slug", "==", searchParam),
        where("fideId", "==", searchParam)
      ), 
      limit(1)
    );
  } else {
    totalCount = (await getCountFromServer(q)).data().count;

    const sortParams = params?.sort || {field: "applyDate", direction: "desc"};
    q = query(q, orderBy(sortParams.field, sortParams.direction));

    const pageParams = params?.page || {};
    if (pageParams.startAfter) {
      q = query(q, startAfter(pageParams.startAfter));
    } else if (pageParams.endBefore) {
      q = query(q, endBefore(pageParams.endBefore));
    }
    q = query(q, limit(pageParams.size || 10));
  }

  const queryResult = await getDocs(q);
  if (queryResult.empty) {
    return null;
  }
  const applications = queryResult.docs.map((queryDoc) => queryDoc.data());
  return {
    data: applications,
    total: totalCount || queryResult.docs.length,
    docRefs: {
      first: queryResult.docs[0],
      last: queryResult.docs[queryResult.docs.length - 1],
    }
  };
};

export const createCoachApplication = async (userId, profilePic, photoId, application) => {
  const imageUploads = [];
  if (photoId) {
    imageUploads.push(uploadBytes(ref(storage, `${STORAGE_DIR_PHOTO_IDS}/${userId}`), photoId));
  }
  if (profilePic) {
    imageUploads.push(uploadBytes(ref(storage, `${STORAGE_DIR_PROFILE_PICS}/${userId}`), profilePic));
  }
  await Promise.all(imageUploads);
  return await setDoc(doc(db, COLLECTION_COACH_APPLICATIONS, userId), {...application, status: 'PENDING'}).catch(console.error);
};

export const approveCoachApplication = ({coachId, coachNumber, elo, mockReviewId, language}) => {
  return new Promise((resolve, reject) => {
    updateDoc(doc(db, COLLECTION_REVIEWS, mockReviewId), {paid: true, status: "IN_QUEUE", language})
      .then(() => {
        updateDoc(doc(db, COLLECTION_COACH_APPLICATIONS, coachId), {coachNumber, elo, mockReviewId, status: "APPROVED"})
          .then(() => resolve())
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

export const loadCoach = async (coachId, includePrivateData) => {
  const coach = await getDoc(doc(db, COLLECTION_COACHES, coachId));
  if (coach.exists()) {
    const privateData = includePrivateData ? await getDoc(doc(db, COLLECTION_COACH_PRIVATE_DATA, coachId)) : null;
    return {
      ...coach.data(),
      ...privateData?.data(),
      id: coachId,
    }
  } 
  return null;
};

export const loadCoachBySlug = async (slug) => {
  const queryResult = await getDocs(query(
    collection(db, COLLECTION_COACHES),
    where("slug", "==", slug),
    limit(1)
  ));
  if (!queryResult.empty) {
    return {
      ...queryResult.docs[0].data(),
      id: queryResult.docs[0].id
    };
  }
  return null;
};

export const loadCoaches = async (params) => {
  let q = query(collection(db, COLLECTION_COACHES));

  let totalCount = 0;

  const searchParam = params?.search;
  if (searchParam) {
    q = query(q, 
      or(
        where("id", "==", searchParam),
        where("slug", "==", searchParam),
        where("coachNumber", "==", searchParam),
      ),
      limit(1)
    );
  } else {
    totalCount = (await getCountFromServer(q)).data().count;

    const sortParams = params?.sort || {field: "approvalDate", direction: "desc"};
    q = query(q, orderBy(sortParams.field, sortParams.direction));

    const pageParams = params?.page || {};
    if (pageParams.startAfter) {
      q = query(q, startAfter(pageParams.startAfter));
    } else if (pageParams.endBefore) {
      q = query(q, endBefore(pageParams.endBefore));
    }
    q = query(q, limit(pageParams.size || 10));
  }

  const queryResult = await getDocs(q);
  if (queryResult.empty) {
    return null;
  }
  const coaches = queryResult.docs.map((queryDoc) => queryDoc.data());
  return {
    data: coaches,
    total: totalCount || queryResult.docs.length,
    docRefs: {
      first: queryResult.docs[0],
      last: queryResult.docs[queryResult.docs.length - 1],
    }
  };
};

export const updateCoachPrice = async (coachId, price) => {
  return await updateDoc(doc(db, COLLECTION_COACHES, coachId), {price}).catch(console.error);
};

export const toggleCoachStatus = async (coachId, coach) => {
  if (coach.status === 'ACTIVE') {
    return await updateDoc(doc(db, COLLECTION_COACHES, coachId), {status: 'PAUSED_MANUAL'})
        .then(() => true)
        .catch((err) => {
          console.error(err);
          return false;
        });
  } 
  if (coach.status === 'PAUSED_MANUAL' || (coach.status === 'ONBOARDING' && coach.paymentsOnboardingComplete && coach.mockReviewApproved)) {
    return await updateDoc(doc(db, COLLECTION_COACHES, coachId), {status: 'ACTIVE'})
        .then(() => true)
        .catch((err) => {
          console.error(err);
          return false;
        });
  }
  return false;
};

export const getResourceStorageUrl = (dir, fileName) => {
  return `https://firebasestorage.googleapis.com/v0/b/check-my-chess.appspot.com/o/${dir}%2F${fileName}?alt=media`;
};

export const getProfilePicUrl = (userId) => {
  return getResourceStorageUrl(STORAGE_DIR_PROFILE_PICS, userId);
};

export const getReviewVideoUrl = (reviewId) => {
  return getResourceStorageUrl(STORAGE_DIR_REVIEW_VIDEOS, `${reviewId}.mp4`);
};

export const getPhotoIdUrl = (userId) => {
  return getDownloadURL(ref(storage, `${STORAGE_DIR_PHOTO_IDS}/${userId}`));
};

export const placeOrder = async (coachId, price, platform, platformUsername, language) => {
  return await httpsCallable(functions, 'placeOrder')({ coachId, price, platformUsername, platform, language })
    .then((response) => {
      return response.data;
    });
};

export const createPaypalOrder = async (orderId) => {
  return await httpsCallable(functions, 'createPaypalOrder')(orderId)
    .then((response) => {
      return response.data;
    });
};

export const capturePaypalOrder = async (paypalOrderId) => {
  return await httpsCallable(functions, 'capturePaypalOrder')(paypalOrderId)
    .then((response) => {
      return response.data;
    });
};

export const deleteVideo = async (reviewId) => {
  return await httpsCallable(functions, 'deleteVideo')(reviewId);
};

export const loadOrder = async (orderId, includeCoach) => {
  const orderDoc = await getDoc(doc(db, COLLECTION_REVIEWS, orderId)).catch(console.error);
  if (orderDoc.exists()) {
    const order = orderDoc.data();
    order.id = orderDoc.id;
    if (includeCoach) {
      const coach = await loadCoach(order.coachId);
      order.coach = coach;
    }
    return order;
  }
  return null;
};

export const loadOrderHistory = async (user) => {
  /* TODO mg: handle paging (keep it simple, do infinite scrolling) */
  const queryResult = await getDocs(query(
    collection(db, COLLECTION_REVIEWS),
    where(user?.isCoach ? "coachId" : "userId", "==", user.id),
    where("paid", "==", true),
    orderBy("orderDate", "desc")
  ));
  if (queryResult.empty) {
    return null;
  }
  const orderHistory = await Promise.all(queryResult.docs.map(async (queryDoc) => {
    const order = queryDoc.data();
    order.id = queryDoc.id;
    if (!user.isCoach) {
      order.coach = await getDoc(doc(db, COLLECTION_COACHES, order.coachId))
        .then((coachDoc) => {
          return {...coachDoc.data(), id: coachDoc.id};
        })
        .catch(console.error);
    }
    return order;
  }));
  return orderHistory;
};

export const getStripeLink = async(coachId) => {
  return await httpsCallable(functions, 'getStripeLink')(coachId)
    .then((response) => {
      return response.data;
    });
};

export const uploadReviewVideo = async (reviewId, reviewVideo) => {
  await updateDoc(doc(db, COLLECTION_REVIEWS, reviewId), {uploadStartTime: serverTimestamp()}).catch((err) => console.error('error setting uploadStartTime on review doc:', err));
  return await 
    uploadBytes(ref(storage, `${STORAGE_DIR_REVIEW_VIDEOS}/${reviewId}`), reviewVideo)
      .then(() => {
        updateDoc(doc(db, COLLECTION_REVIEWS, reviewId), {uploadCompleteTime: serverTimestamp()}).catch((err) => console.error('error setting uploadCompleteTime on review doc:', err))
      })
      .catch((err) => {
        console.error('error uploading review video:', err);
        updateDoc(doc(db, COLLECTION_REVIEWS, reviewId), {uploadErrorTime: serverTimestamp(), status: 'UPLOAD_ERROR'}).catch((err) => console.error('error setting uploadErrorTime on review doc:', err));
      });
};

export const submitReview = async (reviewId) => {
  return updateDoc(doc(db, COLLECTION_REVIEWS, reviewId), {status: 'COMPLETED'}).catch((err) => console.error('error setting status to COMPLETED on review doc:', err));
}

export const retryFailedProcessing = async (reviewId) => {
  try {
    const reviewVideoRef = ref(storage, `${STORAGE_DIR_REVIEW_VIDEOS}/${reviewId}`);
    return await updateMetadata(reviewVideoRef, {customMetadata: {retry: Date.now()}});
  } catch (err) {
    console.error('error retrying failed processing:', err);
  }
};

export const getPaypalOnboardingLink = async(coachId) => {
  return await httpsCallable(functions, 'getPaypalOnboardingLink')(coachId)
    .then((response) => {
      return response.data;
    });
};

/* 
  use the following to upload a json file holding an array to 
  a firestore collection (watch for limit of 20k writes/day)
*/
// const uploadArray = require("path-to-file.json");
// const UPLOAD_COLLECTION_NAME = "collection-name";
// const BUFFER_SIZE = 500;
// export const oneTimeUpload = async () => {
//   let batch = writeBatch(db);
//   let buffer = 0;
//   for(let i=0; i<uploadArray.length; i++) {
//     const obj = uploadArray[i];
//     batch.set(doc(collection(db, UPLOAD_COLLECTION_NAME)), obj);
//     if (++buffer >= BUFFER_SIZE) {
//       buffer = 0;
//       await batch.commit();
//       batch = writeBatch(db);
//     }
//   }
//   if (buffer > 0) {
//     await batch.commit();
//   }
// };