import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { getProfilePicUrl } from "helpers/apiHelpers";
import { Link } from "react-router-dom";
import ISO6391 from 'iso-639-1';
import 'flag-icons/css/flag-icons.min.css';

export default function CoachSnapshot({ coach, small, excludePrice, includeLinks, language }) {

  const languages = language ? [ISO6391.getNativeName(language)] : coach.languages.map((language) => ISO6391.getNativeName(language)).join(', ');
  
  return small ? 
  (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      { includeLinks ?
        <Link to={`/${coach.slug}`} style={{color: '#128080'}} >
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Avatar src={getProfilePicUrl(coach.id)} alt={coach.name} sx={{ width: 96, height: 96, marginBottom: "8px" }}/>
            <Typography variant="h6" textAlign="center">{coach.title} {coach.name}</Typography>
          </Box>
        </Link>
        :
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Avatar src={getProfilePicUrl(coach.id)} alt={coach.name} sx={{ width: 96, height: 96, marginBottom: "8px" }}/>
          <Typography variant="h6">{coach.title} {coach.name}</Typography>
        </Box>
      }
      <Typography variant="body2" sx={{mt: '2px'}}>Language{language ? ":" : "(s):"} {languages}</Typography>
      { !excludePrice &&
        <Box sx={{marginTop: "4px", display: "flex", flexDirection: "row"}}>
          <Typography variant="body1" component="span">${coach?.price?.toFixed(2)}</Typography>
        </Box>
      }
    </Box>
  ) : (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Avatar src={getProfilePicUrl(coach.id)} alt={coach.name} sx={{ width: 192, height: 192, marginBottom: "36px" }}/>
      <Typography variant="h4" textAlign="center">{coach.title} {coach.name}</Typography>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline', mt: "16px"}}>
        <Typography variant="subtitle2">FIDE:</Typography>
        <Typography variant="h6" sx={{ml: '4px'}}>{coach.elo}</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline'}}>
        <Typography variant="subtitle2">Location:</Typography>
        <Typography variant="h6" sx={{ml: '4px'}}><span className={`fi fi-${coach.country.code.toLowerCase()}`}></span> {coach.country.label}</Typography>
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline'}}>
        <Typography variant="subtitle2">Language(s):</Typography>
        <Typography variant="h6" sx={{ml: '4px'}}>{languages}</Typography>
      </Box>
      { !excludePrice && 
        <Box sx={{mt: "24px", display: "flex", flexDirection: "row"}}>
          <Typography variant="h4" component="span">${coach?.price?.toFixed(2)}</Typography>
        </Box>
      }
    </Box>
  );
}