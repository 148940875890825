import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import OrderSnapshot from "components/OrderSnapshot";
import { getReviewVideoUrl } from "helpers/apiHelpers";
import { useNavigate } from "react-router-dom";

export default function PlayerOrderView({ order }) {
  const navigate = useNavigate();

  if (!order) {
    return;
  }

  if (!order.paid) {
    navigate(`/checkout?orderId=${order.id}`);
    return;
  }

  return (
    <Box sx={{p: '24px 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      { order.status === 'COMPLETED' ? 
        <video controls src={getReviewVideoUrl(order.id)} style={{width: '90%', maxWidth: '720px', marginTop: "32px"}} />
        :
        <Box sx={{m: '32px 0', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

          {order.status !== 'PAYMENT_FAILED' && 
            <Typography variant="h3">Thanks!</Typography>}

          {(order.status === 'IN_QUEUE' || order.status === 'PROCESSING' || order.status === 'PROCESSING_ERROR' || order.status === 'READY_TO_SUBMIT') && 
            <Typography variant="body1" sx={{textAlign: 'center'}}>Your order is in progress.  We'll notify you once it's complete.</Typography>}

          {order.status === 'PAYMENT_PROCESSING' && 
            <Typography variant="body1">Your payment is being processed.  We'll notify you once it's complete.</Typography>}

          {order.status === 'PAYMENT_FAILED' && 
            <Typography variant="body1">Sorry, it looks like your payment failed.</Typography>}
          {order.status === 'PAYMENT_FAILED' && 
            <Button variant="outlined" onClick={() => navigate(`/checkout?orderId=${order.id}`)}>Try again</Button>}

        </Box>
      }
      <Card elevation={3} sx={{width: '90%', maxWidth: '720px', p: '16px 0', mt: '32px'}}>
        <OrderSnapshot order={order} title="Order Summary" />
      </Card>


    </Box>
  );
}