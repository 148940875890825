import React, { useContext, useEffect, useState } from "react";
import { Alert, Autocomplete, Avatar, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { authContext } from "contexts/AuthContext";
import LoadingSpinner from "components/LoadingSpinner";
import { createCoachApplication, loadCoachApplication } from "helpers/apiHelpers";
import { Link } from "react-router-dom";
import ISO6391 from 'iso-639-1';
import ISO3166 from 'iso-3166-1-alpha-2';
import 'flag-icons/css/flag-icons.min.css';

export default function CoachApplicationPage() {
  const { user, authenticating, openLoginModal } = useContext(authContext);

  const [loading, setLoading] = useState(true);
  const [existingApplication, setExistingApplication] = useState();

  const [fullName, setFullName] = useState('');
  const [fideId, setFideId] = useState('');
  const [title, setTitle] = useState('GM');
  const [slug, setSlug] = useState('');
  const [lichessLink, setLichessLink] = useState('');
  const [chesscomLink, setChesscomLink] = useState('');
  const [photoId, setPhotoId] = useState();
  const [photoIdUrl, setPhotoIdUrl] = useState();
  const [profilePic, setProfilePic] = useState();
  const [profilePicUrl, setProfilePicUrl] = useState();
  const [aboutMe, setAboutMe] = useState('');
  const [formError, setFormError] = useState({});

  const defaultLocale = new Intl.Locale(navigator.language);
  const defaultCountry = defaultLocale.region ? {
    code: defaultLocale.region, 
    label: ISO3166.getCountry(defaultLocale.region)
  } : {
    code: 'US', 
    label: 'United States'
  };
  const defaultLanguage = defaultLocale.language ? {
    code: defaultLocale.language, 
    name: ISO6391.getName(defaultLocale.language), 
    nativeName: ISO6391.getNativeName(defaultLocale.language)
  } : {
    code: 'en', 
    name: 'English', 
    nativeName: 'English'
  };

  const [country, setCountry] = useState(defaultCountry);
  const [language, setLanguage] = useState(defaultLanguage);
  const [secondLanguage, setSecondLanguage] = useState(null);

  const countryOptions = Object.entries(ISO3166.getData()).map(([code, label]) => ({code, label}));
  const languageOptions = ISO6391.getLanguages(ISO6391.getAllCodes());

  useEffect(() => {
    if (authenticating) {
      setLoading(true);
    } else {
      if (user?.authenticated) {
        setLoading(true);
        loadCoachApplication(user.id).then((application) => {
          if (application) {
            setExistingApplication(application);
          }
          setLoading(false);
        }).catch(console.error);
      } else {
        setLoading(false);
      }
    }
  }, [user, authenticating]);

  const handleNameChange = ({target: {value}}) => {
    setFullName(value);
    if (value) {
      setFormError({...formError, fullName: false});
    }
  };

  const handleFideIdChange = ({target: {value}}) => {
    setFideId(value);
    if (value) {
      setFormError({...formError, fideId: false});
    }
  };

  const handleTitleChange = ({target: {value}}) => {
    setTitle(value);
  };

  const handleLichessLinkChange = ({target: {value}}) => {
    setLichessLink(value);
  };

  const handleChesscomLinkChange = ({target: {value}}) => {
    setChesscomLink(value);
  };

  const handlePhotoIdChange = ({target: {files}}) => {
    const file = files[0]
    setPhotoId(file);
    setPhotoIdUrl(window.URL.createObjectURL(file));
    if (file) {
      setFormError({...formError, photoId: false});
    }
  };

  const handleProfilePicChange = ({target: {files}}) => {
    const file = files[0]
    setProfilePic(file);
    setProfilePicUrl(window.URL.createObjectURL(file));
    if (file) {
      setFormError({...formError, profilePic: false});
    }
  };

  const handleAboutMeChange = ({target: {value}}) => {
    setAboutMe(value);
    // TODO mg: force "about me" min length with something like... if (value?.length >= 100) {
    if (value) {
      setFormError({...formError, aboutMe: false});
    }
  };

  const handleSlugChange = ({target: {value}}) => {
    if (value) {
      value = value
        .toLowerCase()
        .replaceAll(' ', '-')
        .replaceAll(/[^a-z0-9\\-]/g, '');
    }
    setSlug(value);
    if (value) {
      setFormError({...formError, slug: false});
    }
  };

  const handleLanguageChange = (evt, value) => {
    setLanguage(value);
  };

  const handleSecondLanguageChange = (evt, value) => {
    setSecondLanguage(value);
  };

  const handleCountryChange = (evt, value) => {
    setCountry(value);
  };

  const validateForm = () => {
    let isValid = true;

    const error = {};
    if (!fullName?.trim()) {
      error.fullName = true;
      isValid = false;
    }
    if (!fideId?.trim()) {
      error.fideId = true;
      isValid = false;
    }
    if (!profilePic) {
      error.profilePic = true;
      isValid = false;
    }
    if (!photoId) {
      error.photoId = true;
      isValid = false;
    }
    if (!slug?.trim()) {
      error.slug = true;
      isValid = false;
    }
    // TODO mg: force "about me" min length with something like... if (aboutMe?.trim().length < 100) {
    if (!aboutMe?.trim()) {
      error.aboutMe = true;
      isValid = false;
    }

    setFormError(error);
    return isValid;
  };

  const handleSubmit = () => {
    const formValid = validateForm();
    if (!formValid) {
      return;
    }
    setLoading(true);
    createCoachApplication(
      user.id, 
      profilePic,
      photoId, 
      {
        fullName,
        fideId,
        title,
        slug,
        lichessLink,
        chesscomLink,
        aboutMe,
        country,
        language,
        secondLanguage,
      }
    ).then(() => {
      setLoading(false);
      setExistingApplication({status: 'PENDING'});
    }).catch(console.error);
  };

  /**********************************/
  /*             RETURN             */ 
  /**********************************/

  if (loading) {
    return <LoadingSpinner />
  }

  if (existingApplication) {
    switch (existingApplication.status) {
      case 'APPROVED':
        return (
          <Box sx={{mt: '32px', height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
            <Typography variant="h4">Congratulations!</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>Your coaching application has been approved.</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>Checkout your dashboard <Link to="/">here</Link></Typography>
          </Box>
        );
      case 'DENIED':
        return (
          <Box sx={{mt: '32px', height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
            <Typography variant="h4">Thanks!</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>Unfortunately, your application to be a coach on our platform has been denied.</Typography>
            <Typography variant="body1">Please check your email for more information.</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>Have questions?  Email <a href="mailto:info@checkmychess.com">info@checkmychess.com</a></Typography>
          </Box>
        );
      case 'INFORMATION_REQUESTED':
        return (
          <Box sx={{mt: '32px', height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
            <Typography variant="h4">Thanks!</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>We are having trouble verifying some of the information on your application.</Typography>
            <Typography variant="body1">Please check your email for further instructions.</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>Have questions?  Email <a href="mailto:info@checkmychess.com">info@checkmychess.com</a></Typography>
          </Box>
        );
      case 'PENDING':
      default:
        return (
          <Box sx={{mt: '32px', height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
            <Typography variant="h4">Thanks!</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>Your coaching application has been received.</Typography>
            <Typography variant="body1">We'll review it and get back to you soon.</Typography>
            <Typography variant="body1" sx={{mt: '16px'}}>Have questions?  Email <a href="mailto:info@checkmychess.com">info@checkmychess.com</a></Typography>
          </Box>
        );
    }
  }

  return (
    <Box sx={{p: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Box sx={{maxWidth: '400px', width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Typography color="primary" variant="h5" sx={{mb: '32px'}}>Coach Application</Typography>

        {!user?.authenticated ?
          <Button variant="outlined" onClick={openLoginModal}>Sign in to apply</Button>
          :
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Typography variant="body2">Applying with email:</Typography>
            <Typography variant="body1">{user.username}</Typography> 
          </Box>
        }

        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', mt: '32px', mb: '0px'}}>
          <Typography variant="caption"></Typography>
          <Alert severity="info" sx={{mb: '16px', width: '100%'}} >
            <Box sx={{}}>
              <Typography variant="caption">Once approved, your personal order page will be:<br/></Typography>
              <Typography variant="caption">{process.env.REACT_APP_HOST}/<strong>{slug || '{Coach URL}'}</strong></Typography>
            </Box>
          </Alert>
          <TextField 
            error={formError.slug}
            helperText={formError.slug && '* Required field'} 
            fullWidth
            name="slug"
            value={slug}
            label="Coach URL" 
            onChange={handleSlugChange} 
            InputLabelProps={{ shrink: true }}
            required
          />
        </Box>

        <TextField 
          fullWidth 
          error={formError.fullName}
          helperText={formError.fullName && '* Required field'} 
          name="fullName"
          sx={{mt: '32px'}}
          defaultValue={fullName}
          label="Full Name" 
          required
          onChange={handleNameChange} 
          InputLabelProps={{ shrink: true }}
        />

        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: '32px'}}>
          <FormControl>
            <InputLabel required name="title" id="title-select-label">Title</InputLabel>
            <Select
              sx={{width: '100px'}}
              variant="outlined"
              id="title-select"
              value={title}
              label="Title"
              onChange={handleTitleChange}
              required
            >
              <MenuItem value="GM">GM</MenuItem>
              <MenuItem value="IM">IM</MenuItem>
              <MenuItem value="FM">FM</MenuItem>
              <MenuItem value="CM">CM</MenuItem>
              <MenuItem value="NM">NM</MenuItem>
              <MenuItem value="WGM">WGM</MenuItem>
              <MenuItem value="WIM">WIM</MenuItem>
              <MenuItem value="WFM">WFM</MenuItem>
              <MenuItem value="WCM">WCM</MenuItem>
              <MenuItem value="WNM">WNM</MenuItem>
            </Select>
          </FormControl>

          <TextField 
            error={formError.fideId}
            helperText={formError.fideId && '* Required field'} 
            name="fideId"
            label="FIDE ID" 
            defaultValue={fideId}
            sx={{flexGrow: 1, ml: '16px'}}
            onChange={handleFideIdChange} 
            InputLabelProps={{ shrink: true }}
            required
          />
        </Box>

        <Box sx={{mt: '32px', width: '100%'}}>
          <Autocomplete
            id="country-select"
            disablePortal
            sx={{width: '100%'}}
            options={countryOptions}
            disableClearable
            autoHighlight
            defaultValue={defaultCountry}
            getOptionLabel={(option) => `${option.label} (${option.code})`}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <span className={`fi fi-${option.code.toLowerCase()}`}></span>
                <Typography sx={{ml: '16px'}}>{option.label} ({option.code})</Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                required
              />
            )}
            isOptionEqualToValue={(opt, val) => opt.code === val.code}
            onChange={handleCountryChange}
          />
        </Box>

        <Box sx={{mt: '32px', width: '100%'}}>
          <Autocomplete
            id="language-select"
            disablePortal
            sx={{width: '100%'}}
            options={languageOptions}
            disableClearable
            autoHighlight
            defaultValue={defaultLanguage}
            getOptionLabel={(option) => `${option.nativeName} (${option.name})`}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Typography sx={{}}>{option.nativeName} ({option.name})</Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Language"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                required
              />
            )}
            isOptionEqualToValue={(opt, val) => opt.code === val.code}
            onChange={handleLanguageChange}
          />
        </Box>

        <Box sx={{mt: '32px', width: '100%'}}>
          <Autocomplete
            id="second-language-select"
            disablePortal
            sx={{width: '100%'}}
            options={languageOptions}
            autoHighlight
            getOptionDisabled={(option) => option.code === language.code}
            getOptionLabel={(option) => `${option.nativeName} (${option.name})`}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Typography sx={{}}>{option.nativeName} ({option.name})</Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Second Language (optional)"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
            isOptionEqualToValue={(opt, val) => opt.code === val.code}
            onChange={handleSecondLanguageChange}
          />
        </Box>
        
        <Grid2 container sx={{width: '100%'}}>
          <Grid2 item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', padding: '8px'}}>
            { profilePicUrl ?
              <Avatar alt="profile picture" src={profilePicUrl} sx={{ width: 144, height: 144 }}/>
              :
              <AccountCircleOutlinedIcon color="primary" sx={{width: '70%', height: '70%', opacity: 0.1}} />
            }
            <Button color={formError.profilePic ? 'error' : 'primary'} variant="outlined" startIcon={<AccountCircleOutlinedIcon />} component="label" sx={profilePicUrl ? {mt: '8px'} : {}}>
              Profile Pic
              <input hidden accept="image/*" multiple type="file" onChange={handleProfilePicChange}/>
            </Button>
            { formError.profilePic &&
              <Typography variant="caption" color="error" sx={{mt: '3px'}}>* Required field</Typography> 
            }
          </Grid2>
          <Grid2 item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', padding: '8px'}}>
            { photoIdUrl ?
              <img alt="government issued id" src={photoIdUrl} style={{maxWidth: '100%'}}></img>
              :
              <BadgeOutlinedIcon color="primary" sx={{width: '70%', height: '70%', opacity: 0.1}} />
            }
            <Button color={formError.photoId ? 'error' : 'primary'} variant="outlined" startIcon={<BadgeOutlinedIcon />} component="label" sx={photoIdUrl ? {mt: '8px'} : {}}>
              Photo ID
              <input hidden accept="image/*" multiple type="file" onChange={handlePhotoIdChange}/>
            </Button>
            { formError.photoId &&
              <Typography variant="caption" color="error" sx={{mt: '3px'}}>* Required field</Typography> 
            }
          </Grid2>
        </Grid2>

        <TextField 
          error={formError.aboutMe}
          helperText={formError.aboutMe && '* Required field (100 char min)'} 
          fullWidth 
          name="aboutMe"
          label="About Me" 
          defaultValue={aboutMe}
          sx={{mt: '32px'}} 
          multiline 
          rows={4} 
          onChange={handleAboutMeChange}
          InputLabelProps={{ shrink: true }}
          placeholder="This section will be shown on your public coach page. It should describe you and showcase your experience."
          required
        />

        <TextField 
          fullWidth 
          name="lichessLink"
          label="Link to lichess.org profile" 
          defaultValue={lichessLink}
          sx={{mt: '32px'}} 
          onChange={handleLichessLinkChange} 
          InputLabelProps={{ shrink: true }}
        />

        <TextField 
          fullWidth 
          name="chesscomLink"
          label="Link to chess.com profile" 
          defaultValue={chesscomLink}
          sx={{mt: '32px'}} 
          onChange={handleChesscomLinkChange} 
          InputLabelProps={{ shrink: true }}
        />

        {!user?.authenticated ? 
          <Button variant="outlined" onClick={openLoginModal} sx={{mt: '32px'}}>Sign in to apply</Button>
          :
          <Button variant="contained" onClick={handleSubmit} sx={{mt: '32px'}}>Apply</Button>
        }
      </Box>
    </Box>
  );
}