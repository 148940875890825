import React, { useState } from "react";
import { Box, Button, FormControl, IconButton, Modal, Slide, Snackbar, SnackbarContent, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as LogoWithText } from '../assets/logo/logo.svg';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

export default function LoginModal({open, close}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [signingUp, setSigningUp] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [signinError, setSigninError] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    p: '8px 24px'
  };

  const handleEmailChange = ({target: {value}}) => {
    setEmail(value);
    if (value) {
      setEmailError(false);
    }
  };

  const handlePasswordChange = ({target: {value}}) => {
    setPassword(value);
    if (value) {
      setPasswordError(false);
    }
  };

  const handlePasswordConfirmChange = ({target: {value}}) => {
    setPasswordConfirm(value);
    if (value) {
      setPasswordConfirmError(false);
    }
  };

  const validateEmail = () => {
    const validEmailRegex = /^\S+@\S+\.\S+$/;
    const isValid = !!email?.trim().match(validEmailRegex);
    setEmailError(!isValid);
    return isValid;
  };

  const validatePasswords = () => {
    const meetsPasswordRequirements = password.length > 0; // modify this if/when we add minimum password restrictions
    const passwordsMatch = password === passwordConfirm;
    if (!meetsPasswordRequirements) {
      setPasswordError(true);
    }
    if (!passwordsMatch) {
      setPasswordConfirmError(true);
    }
    return meetsPasswordRequirements && passwordsMatch;
  };

  const openLoginToast = () => {
    setToastOpen(true);
    setToastMessage(signingUp ? "Welcome!" : "Welcome back!");
  };

  const closeLoginToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleSignin = () => {
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      openLoginToast();
      handleClose();
    })
    .catch((error) => {
      setSigninError(true);
    });
  };

  const handleSignup = () => {
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      openLoginToast();
      handleClose();
    })
    .catch((error) => {
      //TODO mg: handle signup errors (most probably one being "user already exists")
      console.error(`Error: ${error.code} - ${error.message}`);
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const validEmail = validateEmail();
    if (validEmail) {
      if (!signingUp) {
        handleSignin();
      } else {
        if (validatePasswords()) {
          handleSignup();
        };
      }
    }
  };

  const handleClose = () => {
    setEmail('');
    setPassword('');
    setPasswordConfirm('');
    setEmailError(false);
    setPasswordError(false);
    setPasswordConfirmError(false);
    setSigninError(false);
    setSigningUp(false);
    close();
  };

  const toggleSignup = (evt) => {
    evt.preventDefault();
    if (signingUp) {
      setPasswordConfirm('');
      setPasswordConfirmError(false);
    }
    setSigningUp(!signingUp);
  };

  const onLoginCLose = (evt, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onLoginCLose}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
        keepMounted
      >
        <Box sx={style}>
          {/* HEADER */}
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%"}}>
            <IconButton onClick={handleClose} title="Close" size="large" edge="start" color="inherit" aria-label="login" sx={{position: 'absolute', m: '8px', top: 0, right: 0}}>
              <CloseIcon sx={{color: '#808080'}} />
            </IconButton>
            <LogoWithText fill="#128080" style={{maxWidth: "128px"}} />
          </Box>

          {/* FORM */}
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%"}}>

            { signinError && <Typography variant="button" color="error" sx={{mb: '8px'}}>Email/Password Invalid</Typography> }

            <form style={{width: '100%'}} onSubmit={handleSubmit}>
              <FormControl fullWidth sx={{mt: '8px'}}>
                <TextField 
                  label="Email"
                  name="username"
                  autoComplete="username"
                  InputLabelProps={{ shrink: true }}
                  value={ email || '' } 
                  type="email" 
                  onChange={handleEmailChange} 
                  placeholder="email@example.com"
                  error={emailError}
                  helperText={emailError && 'Invalid email'}
                />
              </FormControl>
              <FormControl fullWidth sx={{mt: '32px'}}>
                <TextField 
                  label="Password"
                  name="password"
                  autoComplete="current-password"
                  InputLabelProps={{ shrink: true }}
                  value={ password || '' } 
                  type="password" 
                  onChange={handlePasswordChange} 
                  error={passwordError}
                  helperText={passwordError && 'Invalid password'}
                />
              </FormControl>
              {signingUp && 
                <FormControl fullWidth sx={{mt: '32px'}}>
                  <TextField 
                    label="Confirm Password"
                    InputLabelProps={{ shrink: true }}
                    value={ passwordConfirm || '' } 
                    type="password" 
                    onChange={handlePasswordConfirmChange}
                    error={passwordConfirmError}
                    helperText={passwordConfirmError && "Password doesn't match"}
                  />
                </FormControl>
              }
              <button hidden style={{display: 'none'}} type="submit" />
            </form>

            {/* TOS & Privacy Policy */}
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pt: '8px', color: '#acacac'}}>
              <Typography variant="caption">By continuing you agree to CheckMyChess's</Typography>
              <Typography variant="caption">
                <a href="/terms" target="_blank" style={{color: "#128080"}}>Terms of Service</a> and <a href="/privacy" target="_blank" style={{color: "#128080"}}>Privacy Policy</a>
              </Typography>
            </Box>

            {/* BUTTON */}
            <Button type="submit" variant="contained" sx={{m: "16px 0"}} onClick={handleSubmit}>{signingUp ? 'Sign Up' : 'Sign In'}</Button>

            {/* SIGNUP/SIGNIN TOGGLE */}
            <Typography variant="caption" sx={{color: '#acacac', mb: '8px'}}>{signingUp ? "Already" : "Don't"} have an account? &nbsp;
              <Typography variant="caption" onClick={toggleSignup} sx={{color: "#128080", textDecoration: "underline", cursor: "pointer"}}>{signingUp ? 'Sign in' : 'Sign up'}</Typography>
            </Typography>
          </Box>

        </Box>
      </Modal>

      <Snackbar 
        open={toastOpen}
        onClose={closeLoginToast} 
        autoHideDuration={3000}
        TransitionComponent={Slide}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <SnackbarContent message={toastMessage} sx={{backgroundColor: "#128080"}}/>
      </Snackbar>
    </>
  );
}