import LoadingSpinner from "components/LoadingSpinner";
import { authContext } from "contexts/AuthContext";
import { getPaypalOnboardingLink } from "helpers/apiHelpers";
import ErrorPage from "pages/error/ErrorPage";
import React, { useContext, useEffect, useState } from "react";

export default function CoachPaymentsPage() {
  const { user, authenticating, coach } = useContext(authContext);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState();

  useEffect(() => {
    if (!authenticating && user?.authenticated) {
      if (user.isCoach) {
        setLoadingText('Redirecting to PayPal...');
        if (coach.paymentsOnboardingComplete) {
          window.location.replace(process.env.REACT_APP_PAYPAL_DASHBOARD_URL);
        } else {
          getPaypalOnboardingLink(coach.id).then((link) => {
            window.location.replace(link.href);
          });
        }
      } else {
        setLoading(false);
      }
    }
  }, [user, authenticating, coach]);

  if (loading) {
    return <LoadingSpinner text={loadingText} />;
  } else if (!user?.authenticated || !user.isCoach) {
    return <ErrorPage errorStatus={404} />;
  }
}