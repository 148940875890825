import React from "react";
import { CircularProgress, Typography } from "@mui/material";

export default function LoadingSpinner({text, small}) {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: small ? "auto" : "calc(70vh)"}}>
      <CircularProgress color="primary" size={small ? 60 : 120} />
      { text && <Typography variant={small ? "body1" : "h5"} color="#128080">{text}</Typography>}
    </div>
  );
}