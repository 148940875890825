import React, { useContext, useEffect, useState } from "react";
import { Box, Card, IconButton, Slide, Snackbar, SnackbarContent } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { authContext } from "contexts/AuthContext";
import ErrorPage from "pages/error/ErrorPage";
import { capturePaypalOrder, createPaypalOrder, loadOrder } from "helpers/apiHelpers";
import LoadingSpinner from "components/LoadingSpinner";
import OrderSnapshot from "components/OrderSnapshot";
import CloseIcon from '@mui/icons-material/Close';

import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export default function CheckoutPage() {
  const { user, authenticating } = useContext(authContext);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState();
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [errorToastOpen, setErrorToastOpen] = useState(false);

  useEffect(() => {
    if (user?.authenticated && location && searchParams) {
      const orderId = searchParams.get('orderId');
      loadOrder(orderId, true).then((order) => {
        setOrder(order);
        setLoading(false);
      });
    }
  }, [user, authenticating, location, searchParams]);

  if (loading) {
    return <LoadingSpinner text="Preparing checkout..." />;
  }

  if (!user?.authenticated && !authenticating) {
    return <ErrorPage errorStatus={401}/>
  }

  if (order.paid) {
    navigate(`/orders/${order.id}`);
    return null;
  }

  const paypalScriptOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    merchantId: order.coach.paypalMerchantId,
    dataPartnerAttributionId: process.env.REACT_APP_PAYPAL_PARTNER_BN_CODE,
    disableFunding: "paylater",
  };

  const createOrder = async () => {
    return createPaypalOrder(order.id);
  };

  const showPaymentError = () => {
    setErrorToastOpen(true);
    setIsCheckingOut(false);
  };

  const onApprove = async (paypalOrder, actions) => {
    setIsCheckingOut(true);
    return capturePaypalOrder(paypalOrder.orderID)
      .then(({paymentCompleted}) => {
        if (paymentCompleted) {
          navigate(`/orders/${order.id}`);
        } else {
          showPaymentError();
        }
      })
      .catch((err) => {
        showPaymentError();
      });
  };

  const CheckoutButtons = () => {
    const [{isPending}] = usePayPalScriptReducer();
    return isPending || isCheckingOut ? <LoadingSpinner small /> : <PayPalButtons createOrder={createOrder} onApprove={onApprove} />;
  };

  const closeErrorToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorToastOpen(false);
  };

  return (
    <Box sx={{mb: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <Card elevation={3} sx={{width: '90%', maxWidth: '720px', p: '16px 0', mt: '32px'}}>
        <OrderSnapshot order={order} title="Order Summary"/>
      </Card>
      <Box sx={{mt: '32px', width: '90%', maxWidth: '720px'}}>
        <PayPalScriptProvider options={paypalScriptOptions}>
          <CheckoutButtons />
        </PayPalScriptProvider>
      </Box>
      <Snackbar
          open={errorToastOpen}
          TransitionComponent={Slide}
          onClose={closeErrorToast} 
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
          <SnackbarContent message="We couldn't process your payment. Please verify your details and try again." sx={{backgroundColor: "#D32E2E"}} 
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={closeErrorToast}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
    </Box>
  );
}