import React, { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Slide, Snackbar, SnackbarContent, Typography } from "@mui/material";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import { formatDateTime, getElapsedTime } from "helpers/datetimeHelpers";
import { loadOrder, toggleCoachStatus } from "helpers/apiHelpers";
import { authContext } from "contexts/AuthContext";
import ProfileSnapshot from "./ProfileSnapshot";

export default function CoachReviewQueue({ coach }) {
  const { user } = useContext(authContext);
  const navigate = useNavigate();

  const [orders, setOrders] = useState({});
  const [isPaused, setIsPaused] = useState(coach?.status !== 'ACTIVE');
  const [pauseConfirmDialogOpen, setPauseConfirmDialogOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastIsError, setToastIsError] = useState(false);

  useEffect(() => {
    if (user?.authenticated && coach) {
      coach.queue?.forEach((orderId) => {
        loadOrder(orderId).then((order) =>{
          setOrders((existing) => {
            return {...existing, [orderId]: order};
          });
        })
      });
    }
  }, [user, coach]);

  const openToast = (message, isError) => {
    setToastMessage(message);
    setToastIsError(!!isError);
    setToastOpen(true);
  };

  const handlePauseToggle = () => {
    if (!isPaused) {
      setPauseConfirmDialogOpen(true);
    } else {
      toggleCoachStatus(user.id, coach).then((success) => {
        if (success) {
          setIsPaused(false);
          openToast("Receiving orders!");
        } else {
          if (!coach.paymentsOnboardingComplete) {
            openToast('Please setup payments. Go to the menu (top right), and click "Payments"', true);
          } else if (!coach.mockReviewApproved) {
            openToast('Please confirm your setup by completing the test order in your queue and awaiting final approval.', true);
          } else {
            openToast("Unknnwn error, try again later.", true);
          }
        }
      });
    }
  };

  const handlePauseConfirmClose = (evt) => {
    if (evt?.target?.id === 'pause-confirm-yes') {
      toggleCoachStatus(user.id, coach).then((success) => {
        if (success) {
          setIsPaused(true);
          openToast("Orders paused!");
        } else {
          openToast("Unknown error, try again later.", true);
        }
      });
    }
    setPauseConfirmDialogOpen(false);
  };

  const getReviews = () => {
    const queue = coach?.queue?.map((orderId) => {
      return orders[orderId];
    }) || [];
    while(queue.length < coach.queueLimit) {
      queue.push({empty: true});
    }
    const gridItems = queue.map((order, i) => {
      if (!order) {
        return (
          <Grid2 key={`slot-${i}-empty`} xs={12} sm={6} md={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px'}}>
            <CircularProgress color="primary" size={64} />
          </Grid2>
        );
      }
      if (order.empty) {
        return (
          <Grid2 key={`slot-${i}-empty`} xs={12} sm={6} md={4}>
            <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "220px", m: '16px'}}>
              <Typography variant="h6" sx={{opacity: "0.3"}}>{isPaused ? 'PAUSED' : 'OPEN'}</Typography>
              {isPaused ? <HourglassDisabledIcon sx={{fontSize: 40}} color="disabled"/> : <HourglassEmptyIcon sx={{fontSize: 40}} color="disabled"/>}
              <Button variant="contained" sx={{mt: "8px"}} disabled>Go to review</Button>
            </Box>
          </Grid2>
        );
      }
      return (
        <Grid2 key={order.id} xs={12} sm={6} md={4}>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "220px", m: '16px'}}>
            <Typography variant="h6" title={formatDateTime(order.orderDate)}>Wait time: {getElapsedTime(order.orderDate)}</Typography>
            <ProfileSnapshot profileData={order} nameFirst />
            <Button variant="contained" sx={{mt: "8px"}} onClick={() => navigate(`/orders/${order.id}`)}>Go to review</Button>
          </Box>
        </Grid2>
      );
    });
    return (
      <Grid2 container sx={{width: "100%", maxWidth: "960px"}} spacing={4}>
        {gridItems}
      </Grid2>
    );
  };

  const getQueueHeader = () => {
    return (
      <Box sx={{width: "85%", maxWidth: "960px", marginTop: "36px", marginBottom: "24px"}} >
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
          <Typography variant="h5">{`Queue (${coach?.queue?.length || 0}/${coach?.queueLimit || 3})`}</Typography>
          <IconButton aria-label="pause-order" title={isPaused ? 'Start receiving orders' : 'Stop receiving orders'} onClick={handlePauseToggle} disabled={coach?.status === 'PAUSED_FULL'}>
            { isPaused ?
              <PlayCircleOutlineIcon sx={{fontSize: "36px"}} />
              :
              <PauseCircleOutlineIcon sx={{fontSize: "36px"}} />
            }
          </IconButton>
        </Box>
        <Divider sx={{borderWidth: "1px"}} />
      </Box>
    );
  };

  const getPauseConfirmDialog = () => {
    return (
      <Dialog open={pauseConfirmDialogOpen} onClose={handlePauseConfirmClose} aria-labelledby="pause-confirm-dialog-title" aria-describedby="pause-confirm-dialog-description">
        <DialogTitle id="pause-confirm-dialog-title">
          {"Stop receiving orders?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="pause-confirm-dialog-description">
            Pausing your queue means you won't receive any new orders.  Are you sure you want to pause?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="pause-confirm-yes" onClick={handlePauseConfirmClose}>YES</Button>
          <Button id="pause-confirm-no" onClick={handlePauseConfirmClose} autoFocus>NO</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handlePauseToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getToast = () => {
    return (
      <Snackbar 
        open={toastOpen} 
        onClose={handlePauseToastClose} 
        autoHideDuration={5000} 
        TransitionComponent={Slide}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <SnackbarContent message={toastMessage} sx={{backgroundColor: toastIsError ? "#D32E2E" : "#128080"}}/>
      </Snackbar>
    );
  };

  return (
    <Box sx={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      {getQueueHeader()}
      {getReviews()}
      {getPauseConfirmDialog()}
      {getToast()}
    </Box>
  );
}