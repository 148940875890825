import React, { useEffect, useState } from "react";
import { 
  Avatar, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography 
} from "@mui/material";
import { approveCoachApplication, getPhotoIdUrl, getProfilePicUrl, loadCoachApplication, loadCoachApplications } from "helpers/apiHelpers";
import { formatDateTime } from "helpers/datetimeHelpers";
import LoadingSpinner from "components/LoadingSpinner";
import { Link } from "react-router-dom";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const PAGE_NEXT = 'page-next';
const PAGE_PREV = 'page-prev';

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_SORT_PARAMS = {field: "status", direction: "desc"};

export default function ApplicationsTabPanel() {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [sortParams, setSortParams] = useState(DEFAULT_SORT_PARAMS);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [photoIdUrl, setPhotoIdUrl] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [totalRows, setTotalRows] = useState(0);
  const [visibleRows, setVisibleRows] = useState({});
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [searchParam, setSearchParam] = useState('');

  useEffect(() => {
    loadCoachApplications({ sort: DEFAULT_SORT_PARAMS, page: {size: DEFAULT_ROWS_PER_PAGE}}).then(result => {
      setApplications(result.data || []);
      setTotalRows(result.total);
      setVisibleRows(result.docRefs);
      setLoading(false);
    });
  }, []);

  const fetchCoachApplications = (opts) => {
    if (opts?.rowsPerPage) {
      setRowsPerPage(opts.rowsPerPage);
    }
    if (opts?.sortParams) {
      setPage(0);
      setSortParams(opts.sortParams);
    }
    const pageParams = { size: opts?.rowsPerPage || rowsPerPage };
    const pagingDirection = opts?.pagingDirection;
    if (pagingDirection) {
      if (pagingDirection === PAGE_NEXT) {
        pageParams.startAfter = visibleRows.last;
      } else {
        pageParams.endBefore = visibleRows.first;
      }
    }
    loadCoachApplications({ sort: opts?.sortParams || sortParams, page: pageParams, search: searchParam}).then(result => {
      setApplications(result?.data || []);
      setTotalRows(result?.total || 0);
      setVisibleRows(result?.docRefs);
      setLoading(false);
      if (result?.data?.length === 1) {
        handleSelectRow(result.data[0].id);
      } else {
        setSelectedApplication(null);
        setSelectedApplicationId(null);
      }
    });
  };

  const fetchCoachApplication = (id) => {
    loadCoachApplication(id).then(application => {
      setSelectedApplication(application);
    });
    getPhotoIdUrl(id).then(url => {
      setPhotoIdUrl(url);
    });
    setProfilePicUrl(getProfilePicUrl(id));
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortParams.field === field) {
      direction = sortParams.direction === "asc" ? "desc" : "asc";
    }
    fetchCoachApplications({sortParams: {field, direction}});
  };

  const handleSelectRow = (id) => {
    setPhotoIdUrl(null);
    setProfilePicUrl(null);
    if (id === selectedApplicationId) {
      setSelectedApplicationId(null);
    } else {
      setSelectedApplicationId(id);
      fetchCoachApplication(id);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage === page) {
      return;
    }
    if (newPage > page) {
      fetchCoachApplications({pagingDirection: PAGE_NEXT});
    } else {
      fetchCoachApplications({pageinDireciton: PAGE_PREV});
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage();
    setPage(0);
    fetchCoachApplications({rowsPerPage: parseInt(event.target.value, 10)});
  };

  const openApproveDialog = () => {
    setApproveDialogOpen(true);
  };
  const closeApproveDialog = () => {
    setApproveDialogOpen(false);
  };

  const handleSubmitApproveDialog = (form) => {
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());
    approveCoachApplication({
      coachId: selectedApplication.id,
      language: selectedApplication.language,
      ...formJson
    }).then(() => closeApproveDialog());
  };

  const handleSearchInputChange = (event) => {
    setSearchParam(event.target.value);
  };

  const handleSearch = () => {
    fetchCoachApplications({search: searchParam});
  };

  const ApplicationsTableHeaderCell = ({label, field}) => {
    return (
      <TableCell sx={{color: '#128080'}}>
        <TableSortLabel 
          active={field === sortParams.field}
          direction={field === sortParams.field ? sortParams.direction : 'asc'}
          onClick={() => handleSort(field)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };

  const SelectedApplicationInfo = ({rowOpen}) => {
    const languages = (selectedApplication?.language.name || '') + (selectedApplication?.secondLanguage ? `, ${selectedApplication.secondLanguage.name}` : '');
    return (
      <Collapse in={rowOpen} timeout="auto" unmountOnExit>
        <Grid2 container sx={{width: "100%", p: "8px 16px"}}>
          <Grid2 key="photo-id" xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {selectedApplication && photoIdUrl && 
              <Link target='_blank' to={photoIdUrl}>
                <img src={photoIdUrl} alt="government issued id" style={{height: 96, cursor: 'pointer'}}></img>
              </Link>
            }
          </Grid2>
          <Grid2 key="profile-picture" xs={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {selectedApplication && profilePicUrl && 
              <Link target='_blank' to={profilePicUrl}> 
                <Avatar src={profilePicUrl} alt="coach avatar" sx={{ width: 96, height: 96, marginBottom: "8px", '&:hover': {cursor: 'pointer'}}}/>
              </Link>
            }
          </Grid2>
          <Grid2 key="application-info-left-column" xs={4}>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
              <InfoField label={"Apply Date"} value={formatDateTime(selectedApplication?.applyDate || {})} />
              <InfoField label={"Name"} value={selectedApplication?.fullName} />
              <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
                <Typography variant="body1" color="primary">Location:&nbsp;</Typography>
                {selectedApplication && 
                  <>
                    <span className={`fi fi-${selectedApplication?.country.code.toLowerCase()}`} style={{marginRight: '4px'}}></span>
                    <Typography>{`${selectedApplication?.country.label} (${selectedApplication?.country.code})`}</Typography>
                  </>
                }
              </Box>
              <InfoField label={"lichess.org"} value={selectedApplication?.lichessLink} />
            </Box>
          </Grid2>
          <Grid2 key="application-info-middle-column" xs={4}>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
              <InfoField label={"ID"} value={selectedApplication?.id} />
              <InfoField label={"Title"} value={selectedApplication?.title} />
              <InfoField label={"Language(s)"} value={languages} />
              <InfoField label={"chess.com"} value={selectedApplication?.chesscomLink} />
            </Box>
          </Grid2>
          <Grid2 key="application-info-right-column" xs={4}>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
              <InfoField label={"Email"} value={selectedApplication?.email} />
              <InfoField label={"FIDE ID"} value={selectedApplication?.fideId} />
              <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
                <Typography variant="body1" color="primary">URL:&nbsp;</Typography>
                <Link to={`/${selectedApplication?.slug}`} target="_blank" style={{color: '#128080'}} >
                  <Typography>{selectedApplication?.slug}</Typography>
                </Link>
              </Box>
            </Box>
          </Grid2>
          <Grid2 key="about-me" xs={12} sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', mt: '8px'}}>
              <Typography variant="body1" color="primary">About me:</Typography>
              <Typography variant="body1">{selectedApplication?.aboutMe}</Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Collapse>
    );
  };

  const ApplicationsTableRow = ({application}) => {
    const isSelected = application.id === selectedApplicationId;
    return (
      <>
        <TableRow 
          sx={{'&:hover': {cursor: 'pointer', backgroundColor: 'rgba(18, 128, 128, 0.10)'}}} 
          selected={isSelected}
          onClick={() => handleSelectRow(application.id)} 
        >
          <TableCell>{formatDateTime(application.applyDate)}</TableCell>
          <TableCell>{application.fullName}</TableCell>
          <TableCell>{application.title}</TableCell>
          <TableCell>
            <span className={`fi fi-${application.country.code.toLowerCase()}`} style={{marginRight: '4px'}}></span>
            {`${application.country.label} (${application.country.code})`}
          </TableCell>
          <TableCell>
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
              {application.status}
              {application.status === "APPROVED" ?
                <Tooltip 
                  title={`Approved on ${formatDateTime(application.approvalDate)}`} 
                  placement="top" 
                  arrow 
                  slotProps={{popper: { modifiers: [{ name: 'offset', options: {offset: [0, -8]} }]}}}
                >
                  <span><Button variant="text" disabled color="primary" startIcon={<VerifiedOutlinedIcon htmlColor="#128080"/>} sx={{'.MuiButton-startIcon': {m: 0}}}/></span>
                </Tooltip>
                :
                <Button disabled={application.id !== selectedApplicationId} variant="outlined" startIcon={<VerifiedOutlinedIcon />} sx={{'.MuiButton-startIcon': {m: 0}}} onClick={() => openApproveDialog()}/>
              }
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{p: 0, border: 0, borderBottom: isSelected ? '1px solid rgb(224, 224, 224)' : 0}} colSpan={5}>
            <SelectedApplicationInfo rowOpen={isSelected}/>
          </TableCell>
        </TableRow>
      </>
    )
  };

  const ApplicationsTable = () => {
    return (
      <Paper sx={{height: "calc(100% - 64px)", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <TableContainer sx={{height: 'calc(100% - 52px)'}}>
          <Table stickyHeader sx={{minWidth: '480px'}}>
            <TableHead>
              <TableRow>
                <ApplicationsTableHeaderCell label="Applied On" field="applyDate"/>
                <ApplicationsTableHeaderCell label="Name" field="fullName" />
                <ApplicationsTableHeaderCell label="Title" field="title" />
                <ApplicationsTableHeaderCell label="Location" field="country" />
                <ApplicationsTableHeaderCell label="Status" field="status" />
              </TableRow>
            </TableHead>
            <TableBody>
            { applications.map((application) => (
              <ApplicationsTableRow key={application.id} application={application} />
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
  };

  const InfoField = ({label, value}) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
        <Typography variant="body1" color="primary">{label}:&nbsp;</Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    );
  };

  const ApproveDialog = () => {
    return (
      <Dialog
        open={approveDialogOpen}
        onClose={() => closeApproveDialog()}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleSubmitApproveDialog(event.currentTarget);
          },
        }}
      >
        <DialogTitle sx={{textAlign: 'center'}}>
          Approve Coach
          <Typography variant="body2">{`${selectedApplication?.fullName} (${selectedApplication?.title})`}</Typography>
        </DialogTitle>
        <DialogContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '240px'}}>
          <TextField fullWidth autoComplete="off" required margin="dense" variant="standard" id="coachNumber" name="coachNumber" label="Coach Number"/>
          <TextField fullWidth autoComplete="off" required margin="dense" variant="standard" id="elo" name="elo" label="ELO"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Link to="https://www.fide.com/search" target="_blank">
                    <SearchIcon color="primary"/>
                  </Link>
                </InputAdornment>
              )
            }}
          />
          <TextField fullWidth autoComplete="off" required margin="dense" variant="standard" id="mockReviewId" name="mockReviewId" label="Mock Review Id"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Link to="/demo" target="_blank">
                    <AddShoppingCartIcon color="primary"/>
                  </Link>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeApproveDialog}>Cancel</Button>
          <Button type="submit" variant="contained">Approve</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const getSearchInput = () => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: '8px', width: '100%'}}>
        <TextField 
          id="search-input-textfield"
          autoComplete="off"
          variant="outlined"
          placeholder="id, email, slug, or fide-id"
          fullWidth
          InputProps={{startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )}}
          onChange={handleSearchInputChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
        />
        <Button variant="outlined" sx={{height: '100%', ml: '8px'}} onClick={handleSearch}>Search</Button>
      </Box>
    );
  };

  return loading ? <LoadingSpinner /> : (
    <Box sx={{height: 'calc(100vh - 280px)', width: '90vw', maxWidth: '1420px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
      { getSearchInput() }
      <ApplicationsTable />
      <ApproveDialog />
    </Box>
  );
}