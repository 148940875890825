import React, { useState } from "react";

import { Box, Tab, Tabs } from "@mui/material";

import ApplicationsTabPanel from "components/admin/ApplicationsTabPanel";
import CoachesTabPanel from "components/admin/CoachesTabPanel";

export default function AdminDashboard() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const AdminTabPanel = ({ children, index, ...other }) => {
    return (
      <div role="tabpanel" hidden={activeTab !== index} id={`admin-tabpanel-${index}`} aria-labelledby={`admin-tab-${index}`} {...other}>
        { activeTab === index && 
          <Box sx={{ p: "8px" }}>
            {children}
          </Box> 
        }
      </div>
    );
  };

  const AdminTab = ({index, ...other}) => {
    return <Tab id={`admin-tab-${index}`} aria-controls={`admin-tabpanel-${index}`} {...other} />;
  };

  return (
    <Box sx={{p: "8px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="admin tabs">
        <AdminTab label="Applications" index={0} />
        <AdminTab label="Coaches" index={1} />
        <AdminTab label="Orders" index={2} />
      </Tabs>
      <AdminTabPanel index={0}>
        <ApplicationsTabPanel />
      </AdminTabPanel>
      <AdminTabPanel index={1}>
        <CoachesTabPanel />
      </AdminTabPanel>
      <AdminTabPanel index={2}>
        Orders
      </AdminTabPanel>
    </Box>
  );
}