import LoadingSpinner from "components/LoadingSpinner";
import { authContext } from "contexts/AuthContext";
import CoachDashboard from "pages/home/CoachDashboard";
import React, { useContext } from "react";
import AdminDashboard from "./AdminDashboard";
import SearchPage from "./SearchPage";

export default function HomePage() {
  const { user, authenticating } = useContext(authContext);

  if (authenticating) {
    return <LoadingSpinner />
  }

  if (user?.authenticated && user.isCoach) {
    return <CoachDashboard />;
  }
  if (user?.authenticated && user.isAdmin) {
    return <AdminDashboard />;
  }
  return <SearchPage />;
};