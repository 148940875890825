import React, { useEffect, useState } from "react";
import { 
  Avatar, Box, Button, Collapse, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography 
} from "@mui/material";
import { getProfilePicUrl, loadCoach, loadCoaches } from "helpers/apiHelpers";
import { formatDateTime } from "helpers/datetimeHelpers";
import LoadingSpinner from "components/LoadingSpinner";
import { Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

const PAGE_NEXT = 'page-next';
const PAGE_PREV = 'page-prev';

const DEFAULT_ROWS_PER_PAGE = 10;
const DEFAULT_SORT_PARAMS = {field: "approvalDate", direction: "desc"};

export default function CoachesTabPanel() {
  const [loading, setLoading] = useState(true);
  const [coaches, setCoaches] = useState([]);
  const [sortParams, setSortParams] = useState(DEFAULT_SORT_PARAMS);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedCoachId, setSelectedCoachId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [totalRows, setTotalRows] = useState(0);
  const [visibleRows, setVisibleRows] = useState({});
  const [searchParam, setSearchParam] = useState('');

  useEffect(() => {
    loadCoaches({ sort: DEFAULT_SORT_PARAMS, page: {size: DEFAULT_ROWS_PER_PAGE}}).then(result => {
      setCoaches(result.data || []);
      setTotalRows(result.total);
      setVisibleRows(result.docRefs);
      setLoading(false);
    });
  }, []);

  const fetchCoaches = (opts) => {
    if (opts?.rowsPerPage) {
      setRowsPerPage(opts.rowsPerPage);
    }
    if (opts?.sortParams) {
      setPage(0);
      setSortParams(opts.sortParams);
    }
    const pageParams = { size: opts?.rowsPerPage || rowsPerPage };
    const pagingDirection = opts?.pagingDirection;
    if (pagingDirection) {
      if (pagingDirection === PAGE_NEXT) {
        pageParams.startAfter = visibleRows.last;
      } else {
        pageParams.endBefore = visibleRows.first;
      }
    }
    loadCoaches({ sort: opts?.sortParams || sortParams, page: pageParams, search: searchParam}).then(result => {
      setCoaches(result?.data || []);
      setTotalRows(result?.total || 0);
      setVisibleRows(result?.docRefs);
      setLoading(false);
      if (result?.data?.length === 1) {
        handleSelectRow(result.data[0].id);
      } else {
        setSelectedCoach(null);
        setSelectedCoachId(null);
      }
    });
  };

  const fetchCoach = (id) => {
    loadCoach(id, true).then(coach => {
      setSelectedCoach(coach);
    });
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortParams.field === field) {
      direction = sortParams.direction === "asc" ? "desc" : "asc";
    }
    fetchCoaches({sortParams: {field, direction}});
  };

  const handleSelectRow = (id) => {
    if (id === selectedCoachId) {
      setSelectedCoachId(null);
      setSelectedCoach(null);
    } else {
      setSelectedCoachId(id);
      fetchCoach(id);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage === page) {
      return;
    }
    if (newPage > page) {
      fetchCoaches({pagingDirection: PAGE_NEXT});
    } else {
      fetchCoaches({pageinDireciton: PAGE_PREV});
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage();
    setPage(0);
    fetchCoaches({rowsPerPage: parseInt(event.target.value, 10)});
  };

  const handleSearchInputChange = (event) => {
    setSearchParam(event.target.value);
  };

  const handleSearch = () => {
    fetchCoaches({search: searchParam});
  };

  const getLanguages = ({language, secondLanguage}) => {
    return (language?.name || '') + (secondLanguage ? `, ${secondLanguage.name}` : '');
  };

  const getQueueCount = ({queueLength, queueLimit}) => {
    return `${queueLength || '0'}/${queueLimit || '-'}`;
  };

  const InfoField = ({label, value}) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
        <Typography variant="body1" color="primary">{label}:&nbsp;</Typography>
        <Typography variant="body1">{value}</Typography>
      </Box>
    );
  };

  const SelectedCoachInfo = ({rowOpen}) => {
    return (
      <Collapse in={rowOpen} timeout="auto" unmountOnExit>
        <Box sx={{p: "8px 16px", display: "flex", flexDirection: "rows", justifyContent: "space-between"}}>
          {/*
  - mockReviewApproved
  - paymentsOnboardingComplete
  - queue
        */}
            <InfoField label={"Coach #"} value={selectedCoach?.coachNumber} />
            <InfoField label={"ID"} value={selectedCoach?.id} />
            <Box sx={{display: 'flex', flexDirection: 'row', mt: '8px', alignItems: 'center'}}>
              <Typography variant="body1" color="primary">URL:&nbsp;</Typography>
              <Link to={`/${selectedCoach?.slug}`} target="_blank" style={{color: '#128080'}} >
                <Typography>{selectedCoach?.slug}</Typography>
              </Link>
            </Box>
            <InfoField label={"Email"} value={selectedCoach?.email} />
          </Box>
      </Collapse>
    );
  };

  const CoachesTableHeaderCell = ({label, field, textAlign}) => {
    return (
      <TableCell sx={{color: '#128080', textAlign: textAlign || 'left'}}>
        <TableSortLabel 
          active={field === sortParams.field}
          direction={field === sortParams.field ? sortParams.direction : 'asc'}
          onClick={() => handleSort(field)}
        >
          {label}
        </TableSortLabel>
      </TableCell>
    );
  };

  const CoachesTableRow = ({coach}) => {
    const isSelected = coach.id === selectedCoachId;
    const profilePicUrl = getProfilePicUrl(coach.id);
    return (
      <>
        <TableRow 
          key={coach.id}
          sx={{'&:hover': {cursor: 'pointer', backgroundColor: 'rgba(18, 128, 128, 0.10)'}}} 
          selected={coach.id === selectedCoachId}
          onClick={() => handleSelectRow(coach.id)} 
        >
          <TableCell>
            <Link target='_blank' to={profilePicUrl}>
              <Avatar src={profilePicUrl} alt="coach avatar" sx={{ width: 52, height: 52, '&:hover': {cursor: 'pointer'}}}/>
            </Link>
          </TableCell>
          <TableCell>{coach.name}</TableCell>
          <TableCell>{coach.title}</TableCell>
          <TableCell>
            <span className={`fi fi-${coach.country.code.toLowerCase()}`} style={{marginRight: '4px'}}></span>
            {`${coach.country.label} (${coach.country.code})`}
          </TableCell>
          <TableCell>{getLanguages(coach)}</TableCell>
          <TableCell>{coach.elo}</TableCell>
          <TableCell>${coach.price}</TableCell>
          <TableCell>{getQueueCount(coach)}</TableCell>
          <TableCell>{coach.status}</TableCell>
          <TableCell>{formatDateTime(coach.approvalDate)}</TableCell>
        </TableRow>
        <TableRow selected>
          <TableCell sx={{p: 0, border: 0, borderBottom: isSelected ? '1px solid rgb(224, 224, 224)' : 0}} colSpan={10}>
            <SelectedCoachInfo rowOpen={isSelected}/>
          </TableCell>
        </TableRow>
      </>
    )
  };

  const CoachesTable = () => {
    return (
      <Paper sx={{height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
        <TableContainer>
          <Table stickyHeader sx={{minWidth: '480px'}} size="small">
            <TableHead>
              <TableRow>
                <TableCell/> { /* empty column header for avatar */}
                <CoachesTableHeaderCell label="Name" field="name" />
                <CoachesTableHeaderCell label="Title" field="title" />
                <CoachesTableHeaderCell label="Location" field="country" />
                <CoachesTableHeaderCell label="Language(s)" field="language" />
                <CoachesTableHeaderCell label="ELO" field="elo" />
                <CoachesTableHeaderCell label="Price" field="price" />
                <CoachesTableHeaderCell label="Queue" field="queueLength" />
                <CoachesTableHeaderCell label="Status" field="status" />
                <CoachesTableHeaderCell label="Approved" field="approvalDate" />
              </TableRow>
            </TableHead>
            <TableBody>
            { coaches.map((coach) => (
              <CoachesTableRow key={coach.id} coach={coach} />
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
  };

  const getSearchInput = () => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mb: '8px', width: '100%'}}>
        <TextField 
          id="search-input-textfield"
          autoComplete="off"
          variant="outlined"
          placeholder="id, coach #, or slug"
          fullWidth
          InputProps={{startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )}}
          onChange={handleSearchInputChange}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
        />
        <Button variant="outlined" sx={{height: '100%', ml: '8px'}} onClick={handleSearch}>Search</Button>
      </Box>
    );
  };

  return loading ? <LoadingSpinner /> : (
    <Box sx={{height: 'calc(100vh - 280px)', width: '90vw', maxWidth: '1420px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
      { getSearchInput() }
      <CoachesTable />
    </Box>
  );
}