import React from "react";
import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CoachSnapshot from "./CoachSnapshot";
import { formatDateTime } from "helpers/datetimeHelpers";
import ProfileSnapshot from "./ProfileSnapshot";

export default function OrderSnapshot({ order, title }) {
  return (
    <Box elevation={3} sx={{p: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      { title &&
        <Typography variant="h6" sx={{pb: '8px', textAlign: 'center'}}>{title}</Typography>
      }
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Grid2 container>
          <Grid2 xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', py: {xs: '12px', sm: '24px'}, minWidth: 240}}>
            <Typography variant="body1" sx={{mb: '8px'}}>Coach</Typography>
            <CoachSnapshot coach={{...order.coach, price: order.price}} small excludePrice includeLinks language={order.language}/>
          </Grid2>
          <Grid2 xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', py: {xs: '12px', sm: '24px'}, minWidth: 240}}>
            <Typography variant="body1" sx={{mb: '8px'}}>Player</Typography>
            <ProfileSnapshot profileData={order} />
          </Grid2>
        </Grid2>
      </Box>
      <Typography variant="body2" sx={{mt: '24px'}}>Order Price: ${order.price}</Typography>
      { order?.orderDate &&
        <Typography variant="body2" sx={{mt: '4px'}}>Order Date: {formatDateTime(order.orderDate)}</Typography>
      }
      { order?.completedDate &&
        <Typography variant="body2" sx={{mt: '4px'}}>Completed: {formatDateTime(order.completedDate)}</Typography>
      }
      <Typography variant="body2" sx={{mt: '4px'}}>Order #: {order.orderNumber}</Typography>
    </Box>
  );
}