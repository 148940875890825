import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Card, Divider, FormControl, FormControlLabel, FormLabel, InputAdornment, Radio, RadioGroup, Slide, Snackbar, SnackbarContent, TextField, Typography } from "@mui/material";
import CoachSnapshot from "components/CoachSnapshot";
import ErrorPage from "pages/error/ErrorPage";
import { Link, useNavigate, useParams } from "react-router-dom";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LoadingSpinner from "components/LoadingSpinner";
import { loadCoachBySlug, placeOrder } from "helpers/apiHelpers";
import { authContext } from "contexts/AuthContext";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function CoachPublicPage() {
  const navigate = useNavigate();
  const pathParams = useParams();

  const { user, openLoginModal } = useContext(authContext);

  const [loading, setLoading] = useState(true);
  const [coach, setCoach] = useState();
  const [platformUsername, setPlatformUsername] = useState(window.localStorage.getItem('cart-platformUsername') || '');
  const [platform, setPlatform] = useState('chesscom');
  const [language, setLanguage] = useState();
  const [platformUsernameInputActive, setPlatformUsernameInputActive] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState('');
  const [toastError, setToastError] = useState(false);

  const ordersEnabled = coach?.status === 'ACTIVE';

  const platformLabel = {
    chesscom: "chess.com",
    lichess: "lichess.org"
  };

  useEffect(() => {
    if (pathParams) {
      const slug = pathParams?.slug;
      if (slug) {
        loadCoachBySlug(slug).then((coach) => {
          setCoach(coach);
          setLanguage(coach?.language?.code);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [pathParams]);

  const toast = (msg, isError) => {
    setToastError(!!isError);
    setToastMsg(msg);
    setToastOpen(true);
  };

  const handlePlatformUsernameChange = (evt) => {
    const val = evt.target.value;
    setPlatformUsername(val);
    if (val) {
      window.localStorage.setItem('cart-platformUsername', val);
    } else {
      window.localStorage.removeItem('cart-platformUsername');
    }
  };

  const handleContinue = () => {
    if (!user?.authenticated) {
      openLoginModal();
      return;
    }
    if (user.isCoach) {
      toast("Coaches placing orders is not supported.", true);
      return;
    }
    if (!platformUsername?.trim()) {
      toast("Missing platform username", true);
      return;
    }
    setSubmitting(true);
    placeOrder(coach.id, coach.price, platform, platformUsername, language)
      .then((orderId) => {
        window.localStorage.removeItem('cart-platformUsername');
        navigate(`/checkout?orderId=${orderId}`);
      })
      .catch((err) => {
        toast(err.message || 'Unknown error, try again later', true);
        setSubmitting(false);
      });
  };

  const getPlatformUsernameInput = () => {
    return (
      <Box sx={{mt: '32px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Card 
          sx={{width: '90%', maxWidth: '400px', p: '8px'}}
          style={!platformUsernameInputActive ? {borderBottom: '1px solid #128080', borderBottomRightRadius: 0, borderBottomLeftRadius: 0} : {}}
          elevation={platformUsernameInputActive ? 3 : 0} 
          onFocus={() => setPlatformUsernameInputActive(true)} 
          onBlur={() => setPlatformUsernameInputActive(false)} 
        >
          <TextField
            fullWidth
            variant="standard"
            name="platformUsername"
            label={`Your ${platformLabel[platform]} username`}
            InputLabelProps={{sx: {width: '200vw', textAlign: 'center', ml: '8px'}}}
            defaultValue={platformUsername}
            onChange={handlePlatformUsernameChange}
            inputProps={{ autoComplete: 'off' }}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityIcon />
                </InputAdornment>
              )
            }}
          />
        </Card>
      </Box>
    );
  };

  const handlePlatformToggle = (evt) => {
    setPlatform(evt.target.value);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getPlatformToggle = () => {
    return (
      <Box sx={{mt: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '90%', maxWidth: '400px'}}>
        <FormLabel id="platform-radio-buttons-group-label">
          <Typography variant="caption">Platform</Typography>
        </FormLabel>
        <FormControl sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <RadioGroup row value={platform} onChange={handlePlatformToggle} name="platform-radio-buttons-group" aria-labelledby="platform-radio-buttons-group-label" sx={{justifyContent: 'center'}}>
            <FormControlLabel value="chesscom" control={<Radio sx={{}} />}
              label={<Typography variant="body2">{platformLabel.chesscom}</Typography>}
            />
            <FormControlLabel value="lichess" control={<Radio sx={{}} />} 
              label={<Typography variant="body2">{platformLabel.lichess}</Typography>} 
            />
          </RadioGroup>
        </FormControl>
      </Box>
    )
  };

  const getCtaButton = () => {
    const onClickHandler = user?.authenticated ? handleContinue : openLoginModal;
    const variant = user?.authenticated ? "contained" : "outlined";
    const text = user?.authenticated ? "Place Order" : "Login to place order";
    const disabled = user?.authenticated && !ordersEnabled;
    return (
      <Button size="large" sx={{mt: "36px"}} variant={variant} disabled={ disabled } onClick={onClickHandler}>
        {text}
      </Button>
    );
  };

  const getToast = () => {
    const messageComponent = (
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {toastError && <ErrorOutlineIcon sx={{mr: '8px'}} />}
        {toastMsg}
      </Box>
    );
    return (
      <Snackbar 
        open={toastOpen} 
        onClose={handleToastClose} 
        autoHideDuration={4000}
        TransitionComponent={Slide}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      >
        <SnackbarContent message={messageComponent} sx={{backgroundColor: toastError ? "#D32E2E" : "#128080"}} />
      </Snackbar>
    );
  };

  const getLanguageToggle = () => {
    return (
      <Box sx={{mt: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '90%', maxWidth: '400px'}}>
        <FormLabel id="language-radio-buttons-group-label">
          <Typography variant="caption">Language</Typography>
        </FormLabel>
        <FormControl sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
          <RadioGroup row value={language} onChange={handleLanguageToggle} name="language-radio-buttons-group" aria-labelledby="language-radio-buttons-group-label" sx={{justifyContent: 'center'}}>
            <FormControlLabel value={coach.language.code} control={<Radio />}
              label={<Typography variant="body2">{coach.language.nativeName}</Typography>}
            />
            {coach.secondLanguage && <FormControlLabel value={coach.secondLanguage.code} control={<Radio sx={{}} />} 
              label={<Typography variant="body2">{coach.secondLanguage.nativeName}</Typography>} 
            />}
          </RadioGroup>
        </FormControl>
      </Box>
    )
  };

  const handleLanguageToggle = (evt) => {
    setLanguage(evt.target.value);
  };

  if (loading || submitting) {
    return <LoadingSpinner text={submitting && 'Generating order...'} />;
  }

  if (!coach) {
    return <ErrorPage errorStatus={404} />
  }

  return (
    <Box sx={{p: '32px 8px 64px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <CoachSnapshot coach={coach} />
      { getPlatformUsernameInput() }
      { getPlatformToggle() }
      { getLanguageToggle() }
      { getCtaButton() }
      { !ordersEnabled && user?.authenticated && 
        <Box sx={{mt: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <Typography variant="caption">Unfortunately, this coach is not currently receiving orders.</Typography>
          <Link to={{pathname: "/"}} style={{color: '#128080'}}>
            <Typography variant="caption">Look for another coach</Typography>
          </Link> 
        </Box>
      }
      <Divider sx={{width: "80%", maxWidth: "720px", marginTop: "48px", marginBottom: "24px", borderWidth: "1px"}} >
        <Typography variant="h6">About Me</Typography>
      </Divider>
      <Box sx={{width: "80vw", maxWidth: "720px", display: "flex", justifyContent: "center"}}>
        <Typography variant="body" sx={{textAlign: "justify"}}>{coach.aboutMe}</Typography>
      </Box>
      
      { getToast() }
    </Box>
  );
};