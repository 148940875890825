import React, { useContext, useState } from "react";
import { AppBar, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import ErrorIcon from '@mui/icons-material/Error';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { ReactComponent as LogoWithText } from '../assets/logo/logo.svg';
import { ReactComponent as LogoIcon } from '../assets/logo/icon.svg';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authContext } from "contexts/AuthContext";

import { auth } from "../firebase";
import { signOut } from "firebase/auth";

export default function Header() {
  const { user, coach, openLoginModal } = useContext(authContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [logoutConfirmDialogOpen, setLogoutConfirmDialogOpen] = useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(Boolean(userMenuAnchorEl));

  const openUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
    setUserMenuOpen(true);
  };

  const closeUserMenu = () => {
    setUserMenuAnchorEl(null);
    setUserMenuOpen(false);
  };

  const handleUserMenuClose = (event) => {
    closeUserMenu();
    switch (event?.target?.id) {
      case 'dashboard-menu-item':
      case 'home-menu-item':
        navigate('/');
        break;
      case 'coach-page-menu-item':
        navigate(`/${coach?.slug}`);
        break;
      case 'order-history-menu-item':
        navigate('/orders');
        break;
      default: 
        // no-op;
        break;
    }
  };

  const handleLogout = () => {
    setLogoutConfirmDialogOpen(true);
  };
  
  const handleLogoutConfirmClose = (evt) => {
    if (evt?.target?.id === 'logout-confirm-yes') {
      signOut(auth).then(() => navigate('/'));
    }
    setLogoutConfirmDialogOpen(false);
    closeUserMenu();
  };

  const getPageTitle = () => {
    if (user?.authenticated) {
      if (location.pathname === '/' && user.isCoach) {
        return 'Dashboard';
      }
      if (location.pathname === '/checkout') {
        return 'Checkout';
      }
      if (location.pathname.startsWith('/orders/')) {
        return 'Order';
      }
      if (location.pathname.startsWith('/orders')) {
        return 'Order History';
      }
    }
    return (
      <Link to="https://checkmychess.com">
        <LogoWithText height={64} fill="#ffffff" style={{ verticalAlign: 'middle', cursor: 'pointer' }} />
      </Link>
    );
  };

  /**
   * Determines if the menu icon should show an alert overlay (red warning symbol)
   * @returns boolean representing if user menu should have an alert notification
   */
  const getShowMenuAlert = () => {
    return user?.authenticated && user.isCoach && !coach.paymentsOnboardingComplete;
  };

  const getCoachMenuItems = () => {
    return [
      <MenuItem key="dashboard-menu-item" id="dashboard-menu-item" onClick={handleUserMenuClose} disabled={location.pathname === '/'}>
        <ListItemIcon>
          <DashboardOutlinedIcon />
        </ListItemIcon>
        Dashboard
      </MenuItem>,
      <MenuItem key="coach-page-menu-item" id="coach-page-menu-item" onClick={handleUserMenuClose} disabled={location.pathname === `/${coach.slug}`}>
        <ListItemIcon>
          <ContactPageOutlinedIcon />
        </ListItemIcon>
        Coach Page
      </MenuItem>,
      <MenuItem key="order-history-menu-item" id="order-history-menu-item" onClick={handleUserMenuClose} disabled={location.pathname === '/orders'}>
        <ListItemIcon>
          <HistoryOutlinedIcon />
        </ListItemIcon>
        Order History
      </MenuItem>,
      <Link key="payments-menu-item" to="/payments" target="_blank" style={{color: 'inherit', textDecoration: 'none'}}>
        <MenuItem id="payments-menu-item" onClick={handleUserMenuClose} disabled={location.pathname === '/payments'}>
          <ListItemIcon>
            <PaymentsOutlinedIcon />
          </ListItemIcon>
          <Typography sx={{mr: '4px'}}>Payments</Typography>
          {getShowMenuAlert() && <ErrorIcon color="error"/>}
        </MenuItem>
      </Link>,
      <Divider key="divider" />,
      <MenuItem key="logout-menu-item" id="logout-menu-item" onClick={handleLogout} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        <em style={{ fontSize: '12px', color: '#acacac' }}>{user.username}</em>
        Sign out
      </MenuItem>,
    ]
  };

  const getPlayerMenuItems = () => {
    return [
      <MenuItem key="home-menu-item" id="home-menu-item" onClick={handleUserMenuClose} disabled={location.pathname === '/'}>
        <ListItemIcon>
          <SearchOutlinedIcon />
        </ListItemIcon>
        Find a coach
      </MenuItem>,
      <MenuItem key="order-history-menu-item" id="order-history-menu-item" onClick={handleUserMenuClose} disabled={location.pathname === '/orders'}>
        <ListItemIcon>
          <HistoryOutlinedIcon />
        </ListItemIcon>
        Order History
      </MenuItem>,
      <Divider key="divider" />,
      <MenuItem key="logout-menu-item" id="logout-menu-item" onClick={handleLogout} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        <em style={{ fontSize: '12px', color: '#acacac' }}>{user.username}</em>
        Sign out
      </MenuItem>,
    ]
  };

  const getUserMenu = () => {
    if (location.pathname === '/payments' && user.isCoach) {
      return <Box sx={{ width: "48px" }} />;
    }
    return (
      <>
        <IconButton
          id="user-menu-button"
          title="Menu"
          size="large"
          edge="start"
          color="inherit"
          aria-label="user-menu"
          onClick={openUserMenu}
          aria-controls={userMenuOpen ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={userMenuOpen ? 'true' : undefined}
        >
          {getShowMenuAlert() && !userMenuOpen ? 
            <Badge badgeContent="!" color="error" overlap="circular" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
              <LogoIcon fill="#ffffff" width={"48px"} />
            </Badge>
            :
            <LogoIcon fill="#ffffff" width={"48px"} />
          }
          
        </IconButton>
        <Menu
          id="user-menu"
          anchorEl={userMenuAnchorEl}
          open={userMenuOpen}
          onClose={handleUserMenuClose}
          MenuListProps={{ 'aria-labelledby': 'user-menu-button' }}
        >
          {user?.authenticated && user?.isCoach ? getCoachMenuItems() : getPlayerMenuItems()}
        </Menu>
      </>
    );
  };

  return (
    <>
      <AppBar position="sticky" sx={{ textAlign: "center" }}>
        <Toolbar sx={{ margin: "auto", maxWidth: "984px", width: "90%" }}>
          <Box sx={{ width: "48px" }} />

          <Typography fontFamily="AgneRegular" variant="h5" component="div" sx={{ flexGrow: 1 }}>
            {getPageTitle()}
          </Typography>

          {user?.authenticated ?
            getUserMenu() :
            <IconButton onClick={openLoginModal} title="Login" size="large" edge="start" color="inherit" aria-label="login" >
              <LockIcon width={"48px"} />
            </IconButton>
          }
        </Toolbar>
      </AppBar>
      <Dialog open={logoutConfirmDialogOpen} onClose={handleLogoutConfirmClose} aria-labelledby="logout-confirm-dialog-title" aria-describedby="logout-confirm-dialog-description">
        <DialogTitle id="logout-confirm-dialog-title">
          {"Sign out?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-confirm-dialog-description">
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="logout-confirm-yes" onClick={handleLogoutConfirmClose}>YES</Button>
          <Button id="logout-confirm-no" onClick={handleLogoutConfirmClose} autoFocus>NO</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}