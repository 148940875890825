import React, { useEffect, useState } from "react";
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { InstantSearch, Configure, useSearchBox, useHits, useRefinementList, useRange, useClearRefinements } from 'react-instantsearch';
import { Avatar, Backdrop, Badge, Box, Checkbox, FormControlLabel, FormGroup, Paper, Slider, TextField, ToggleButton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ISO6391 from 'iso-639-1';

import { getProfilePicUrl } from "helpers/apiHelpers";
import { Link } from "react-router-dom";

import './SearchPage.css';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY);

export default function SearchPage() {
  const [showingFilters, setShowingFilters] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState([]);

  const SearchField = () => {
    const { query, refine } = useSearchBox();
    return (
      <TextField 
        size="small"
        value={query} 
        onChange={(e) => refine(e.target.value)} 
        placeholder="Search by name..."
        InputProps={{
          endAdornment: <SearchIcon style={{fill: '#128080'}}/>
        }}
        sx={{mr: "16px", backgroundColor: 'white', borderRadius: '4px'}}
      />
    );
  };

  const Result = ({ item }) => {
    const resultAvatarSize = {xs: 176, xs_sm: 160};
    return (
      <Grid2 xs={12} xs_sm={6} sm={4} md={3}>
        <Link to={{pathname: `/${item.slug}`}} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: '8px', py: '16px', cursor: 'pointer'}}>
            <Badge overlap="circular" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} 
              badgeContent={
                <Avatar sx={{width: 40, height: 40, bgcolor: 'white', border: '1px solid #128080'}}>
                  <Typography sx={{fontSize: '16px'}} color="primary">{item.title}</Typography>
                </Avatar>
            }>
              <Avatar src={getProfilePicUrl(item.objectID)} alt={item.name} sx={{ width: resultAvatarSize, height: resultAvatarSize, marginBottom: "12px" }}/>
            </Badge>
            <Typography sx={{my: '8px', fontSize: '16px'}} align="center">{item.name}</Typography>
            <Typography sx={{fontSize: '14px'}}>{item.languages.map((lang) => ISO6391.getNativeName(lang)).join(', ')}</Typography>
            <Typography sx={{fontSize: '14px'}} align="center"><span className={`fi fi-${item.country.code.toLowerCase()}`}></span> {item.country.label}</Typography>
            <Typography sx={{mt: '8px'}}>${item.price}</Typography>
          </Box>
        </Link>
      </Grid2>
    );
  };

  const sortItemsByCount = (items) => {
    return items.toSorted((a, b) => {
      if (a.count < b.count) {
        return 1;
      }
      if (a.count > b.count) {
        return -1;
      }
      return 0;
    });
  };

  const applyValueFilter = (category, value) => {
    const filterValue = `${category}:${value}`;
    const index = currentlyAppliedFilters.indexOf(filterValue);
    if (index !== -1) {
      setCurrentlyAppliedFilters([
        ...currentlyAppliedFilters.slice(0, index),
        ...currentlyAppliedFilters.slice(index + 1)
      ]);
    } else {
      setCurrentlyAppliedFilters([
        ...currentlyAppliedFilters,
        filterValue
      ]);
    }
  };

  const applyBooleanFilter = (category, apply) => {
    const index = currentlyAppliedFilters.indexOf(category);
    if (apply && index === -1) {
      setCurrentlyAppliedFilters([...currentlyAppliedFilters, category]);
    }
    if (!apply && index !== -1) {
      setCurrentlyAppliedFilters([
        ...currentlyAppliedFilters.slice(0, index),
        ...currentlyAppliedFilters.slice(index + 1)
      ]);
    }
  };

  const TitleFilter = () => {
    const {items, refine} = useRefinementList({attribute: "title"});
    const handleTitleSelect = (title) => {
      refine(title);
      applyValueFilter('title', title);
    };
    return (
      <FormGroup>
        {sortItemsByCount(items).map(item => (
          <FormControlLabel
            key={item.value}
            control={
              <Checkbox 
                checked={item.isRefined} 
                onChange={() => handleTitleSelect(item.value)}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{p: '0', m: '0', ml: '8px'}}
              />
            } 
            label={
              <Box sx={{display: 'flex'}}>
                <Typography>{item.value}</Typography>
                <Typography color="#afafaf" sx={{ml: '4px'}}>({item.count})</Typography>
              </Box>
            }
          />
        ))}
      </FormGroup>
    );
  };

  const LanguageFilter = () => {
    const {items, refine} = useRefinementList({attribute: "languages"});
    const handleLanguageSelect = (lang) => {
      refine(lang);
      applyValueFilter('lang', lang);
    };
    return (
      <FormGroup>
        {sortItemsByCount(items).map(item => (
          <FormControlLabel
            key={item.value}
            control={
              <Checkbox 
                checked={item.isRefined} 
                onChange={() => handleLanguageSelect(item.value)}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{p: '0', m: '0', ml: '8px'}}
              />
            } 
            label={
              <Box sx={{display: 'flex'}}>
                <Typography>{ISO6391.getNativeName(item.value)}</Typography>
                <Typography color="#afafaf" sx={{ml: '4px'}}>({item.count})</Typography>
              </Box>
            }
          />
        ))}
      </FormGroup>
    );
  };

  const PriceRangeFilter = () => {
    const {start, range, canRefine, refine} = useRange({attribute: 'price'});
    const {min, max} = range;
    const [priceFilterValues, setPriceFilterValues] = useState([min, max]);

    const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
    const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);

    useEffect(() => {
      setPriceFilterValues([from, to]);
    }, [from, to]);

    const handlePriceFilterChange = (e, value) => {
      setPriceFilterValues(value);
    };
    const handlePriceFilterCommit = (e, value) => {
      refine(value);
      const priceFilterApplied = value[0] !== min || value[1] !== max;
      applyBooleanFilter('price', priceFilterApplied);
    };

    return (
      <Box sx={{width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <Typography>{`$${from} - $${to}`}</Typography>
        </Box>
        <Slider
          getAriaLabel={() => 'Price range'}
          valueLabelDisplay="auto"
          value={priceFilterValues}
          min={min}
          max={max}
          onChange={handlePriceFilterChange}
          onChangeCommitted={handlePriceFilterCommit}
          disabled={!canRefine}
        />
      </Box>
    );
  };

  const FilterMenuSectionTitle = ({text}) => {
    return <Typography sx={{mb: '4px'}} color="primary">{text}</Typography>
  };

  const FilterMenuSection = ({title, comp, sx}) => {
    return (
      <Box sx={{...sx, display: 'flex', flexDirection: 'column', width: '100%'}} >
        <FilterMenuSectionTitle text={title} />
        {comp}  
      </Box>
    );
  };

  const FilterMenu = () => {
    const { refine } = useClearRefinements();
    const clearAllFilters = () => {
      if (currentlyAppliedFilters.length) {
        refine();
        setCurrentlyAppliedFilters([]);
      }
    };
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', py: '8px', alignItems: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'row', width: '80%'}}>
          <FilterMenuSection sx={{alignItems: 'flex-start', flex: 2}} title="Language" comp={<LanguageFilter />} />
          <FilterMenuSection sx={{alignItems: 'flex-start', flex: 1}} title="Title" comp={<TitleFilter />} />
        </Box>
        <FilterMenuSection sx={{mt: '16px', pb: '16px', alignItems: 'center'}} title="Price" comp={<PriceRangeFilter />}/>
        <Typography 
          variant="body2" 
          onClick={clearAllFilters} 
          sx={{
            textDecoration: "underline", 
            mb: '8px', 
            cursor: currentlyAppliedFilters.length ? 'pointer' : 'not-allowed',
            color: currentlyAppliedFilters.length ? '#128080' : '#cbcbcb'
          }}
        >
          Clear all
        </Typography>
      </Box>
    );
  };

  const showHideFilter = () => {
    const newState = !showingFilters;
    setShowingFilters(newState);
    if (newState) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const FilterButton = () => {
    return (
      <Badge overlap="circular" anchorOrigin={{vertical: 'top', horizontal: 'right'}} badgeContent={currentlyAppliedFilters.length} color="primary">
        <ToggleButton
          value={showingFilters}
          selected={showingFilters} 
          onClick={showHideFilter} 
          sx={{height: "40px", borderColor: '#cbcbcb'}}
          style={{ backgroundColor: 'white'}}
        >
          <TuneIcon />
        </ToggleButton>
      </Badge>
    );
  };

  const SearchHeaderBar = () => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 12}}>
        <SearchField />
        <FilterButton />
      </Box>
    );
  };

  const Results = () => {
    const { items } = useHits();
    return (
      <Grid2 container sx={{width: "100%", maxWidth: "880px", mt: '8px'}} spacing={0}>
        {items.map(item => <Result key={item.objectID} item={item} />)}
      </Grid2>
    );
  };

  const PopupFilter = () => {
    return (
      <Paper sx={{display: showingFilters ? 'auto' : 'none', position: 'fixed', top: 64, maxHeight: "480px", width: '100vw', maxWidth: '400px', pt: '64px'}}>
        <FilterMenu />
      </Paper>
    );
  };

  return (
    <InstantSearch searchClient={searchClient} indexName="coaches" future={{preserveSharedStateOnUnmount: true}}>
      <Configure restrictSearchableAttributes={["name", "slug"]} />
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'sticky', top: 80, mt: '16px', mx: '16px', pb: 0, zIndex: 11}}>
          <SearchHeaderBar />
          <PopupFilter />
        </Box>
        <Results />
      </Box>
      <Backdrop sx={{position: 'fixed', zIndex: 10}} open={showingFilters} onClick={showHideFilter} />
    </InstantSearch>
  );
}