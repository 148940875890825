import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function ProfileSnapshot({ profileData: {platform, platformUsername, platformProfile}, empty, nameFirst}) {
  if (empty) {
    return (
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        <Typography variant="h6">Empty Slot</Typography>  
      </Box>
    );
  }

  const playerUrl = platform === 'lichess' ? `${platformProfile.url}/all` : platformProfile.url;
  
  return nameFirst ? (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      
      <Typography variant="h6">
        <Link target="_blank" to={playerUrl} style={{color: '#128080'}}>{platformUsername}</Link>
      </Typography>
      
      <Typography variant="body1">Platform: {platform === "chesscom" ? 'chess.com' : 'lichess.org'}</Typography>

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mr: "16px"}}>
          <Typography variant="h6">Rapid</Typography>
          <Typography variant="h6">{platformProfile.eloRapid}</Typography>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", ml: "16px"}}>
          <Typography variant="h6">Blitz</Typography>
          <Typography variant="h6">{platformProfile.eloBlitz}</Typography>
        </Box>
      </Box>
      
    </Box>
  ) : (
    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      
      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: {sm: "104px"}}}>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mr: "16px"}}>
          <Typography variant="h6">Rapid</Typography>
          <Typography variant="h6">{platformProfile.eloRapid}</Typography>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", ml: "16px"}}>
          <Typography variant="h6">Blitz</Typography>
          <Typography variant="h6">{platformProfile.eloBlitz}</Typography>
        </Box>
      </Box>

      <Typography variant="h6">
        <Link target="_blank" to={playerUrl} style={{color: '#128080'}}>{platformUsername}</Link>
      </Typography>
      
      <Typography variant="body2">Platform: {platform === "chesscom" ? 'chess.com' : 'lichess.org'}</Typography>
      
    </Box>
  );
}